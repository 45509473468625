<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>裁判系统</el-breadcrumb-item>
      <el-breadcrumb-item>IBC线上挑战赛</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 条件筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>选择考场</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" :rules="queryFormRule" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="届" prop="ibcSession">
              <el-select placeholder="请选择" style="width: 100%" v-model="queryForm.ibcSession" @change="selectChanged"
                clearable>
                <el-option v-for="item in dict_ibcSession" :key="item.dictCode" :label="item.dictLabel"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="季度" prop="ibcSeason">
              <el-select placeholder="请选择" style="width: 100%" v-model="queryForm.ibcSeason" @change="selectChanged"
                clearable>
                <el-option v-for="item in dict_ibcSeason" :key="item.dictCode" :label="item.dictLabel"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="轮次" prop="ibcRound">
              <el-select placeholder="请选择" style="width: 100%" v-model="queryForm.ibcRound" @change="selectChanged"
                clearable>
                <el-option v-for="item in dict_ibcRound" :key="item.dictCode" :label="item.dictLabel"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="考场名称" prop="hallId">
              <el-select v-model="queryForm.hallId" filterable style="width: 100%">
                <el-option v-for="item in examRoomList" :key="item.id" :label="item.hallName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="getRoundInfoData()">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 考场信息 -->
    <el-card style="margin-top: 15px" v-if="matchInfoShow">
      <el-row :gutter="20" class="el-row">
        <el-col :span="6">
          <span class="exam_span_class">考场名称: <span style="color: #1341ca">{{ examRoomInfo.hallName }}</span></span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">考场类型: <span style="color: #ff4800">{{ matchTypeClass }}</span></span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">组别: <el-tag type="warning">{{ gradeName }}</el-tag></span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">会议号: <el-button type="primary" plain icon="el-icon-link" @click="copy">{{
            examRoomInfo.meetingNum }}</el-button></span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="el-row">
        <el-col :span="6">
          <span class="exam_span_class">比赛开始时间: <span style="color: #10ac16">{{ examRoomInfo.startTime }}</span></span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">比赛结束时间: <span style="color: #df2c22">{{ examRoomInfo.startTime }}</span></span>
        </el-col>
        <el-col :span="6" v-if="roundType === 'knockout' && queryForm.ibcRound !== '5'">
          <span class="exam_span_class">晋级人数： {{ examRoomInfo.promotionNum }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="0" type="flex" justify="end" v-if="roundType === 'knockout'">
        <el-col>
          <el-button
            type="primary"
            plain
            icon="el-icon-setting"
            :disabled="examRoomInfo.isIng === 1"
            @click="showSetPromotionNumberDialog"
            >设置晋级人数</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-s-data"
            :disabled="examRoomInfo.isIng === 1"
            @click="showRankInfoDialog()"
            >查看实时排名</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-refresh"
            :disabled="examRoomInfo.isIng === 1"
            @click="resetAllRoundsDialogVisable = true"
            >重置所有轮次</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            :disabled="examRoomInfo.isIng === 1"
            @click="getKnockoutMatchInfo"
            >查询</el-button
          >
          <!-- sq 隐藏 -->
          <!-- <el-button
            type="danger"
            plain
            icon="el-icon-check"
            :disabled="examRoomInfo.isIng === 1"
            @click="finishKnockoutMatchInfo"
            >比赛结束</el-button
          > -->
        </el-col>
      </el-row>
    </el-card>
    <!-- 二轮比赛列表信息 -->
    <el-card class="list_card" style="margin-top: 20px" v-if="roundType === 'point' && matchInfoShow">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">比赛信息</div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right">
            <el-button type="primary" @click="addExamineeClick">添加考生</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table header-cell-class-name="tableHeaderStyle" :data="examMatchInfo"
        :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName" width="100px"></el-table-column>
        <el-table-column label="手机号" prop="phone" width="100px"></el-table-column>
        <el-table-column label="座位号" prop="userSerialNo" width="100px"></el-table-column>
        <el-table-column label="学校" prop="schoolName" width="300px"></el-table-column>
        <el-table-column label="Round1（5分）" prop="roundOne">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.roundOne" true-label="1" false-label="0"
              :style="{ '--fill-color': '#67C23A' }" @change="onPassClickChange(scope)">
              对
            </el-checkbox>
            <el-checkbox v-model="scope.row.roundOne" true-label="0" false-label="1" @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }">
              错
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Round2（8分）" prop="roundTwo">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.roundTwo" true-label="1" false-label="0"
              :style="{ '--fill-color': '#67C23A' }" @change="onPassClickChange(scope)">
              对
            </el-checkbox>
            <el-checkbox v-model="scope.row.roundTwo" true-label="0" false-label="1" @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }">
              错
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Round3（10分）" prop="roundThree">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.roundThree" true-label="1" false-label="0"
              :style="{ '--fill-color': '#67C23A' }" @change="onPassClickChange(scope)">
              对
            </el-checkbox>
            <el-checkbox v-model="scope.row.roundThree" true-label="0" false-label="1" @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }">
              错
            </el-checkbox>
          </template></el-table-column>
        <el-table-column label="Round4（12分）" prop="roundFour">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.roundFour" true-label="1" false-label="0"
              :style="{ '--fill-color': '#67C23A' }" @change="onPassClickChange(scope)">
              对
            </el-checkbox>
            <el-checkbox v-model="scope.row.roundFour" true-label="0" false-label="1" @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }">
              错
            </el-checkbox>
          </template></el-table-column>
        <el-table-column label="总分" prop="score">
          <template scope="scope">
            <span class="scoreClass">{{ scope.row.score }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete" size="small"
              @click="judgeDeleteExamUserChange(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="card-bottom">
        <el-button type="primary" style="width: 200px" @click="examInfoSaveClicked">保存</el-button>
      </div>
    </el-card>
    <!-- 三四五轮比赛信息列表 -->
    <el-tabs class="cpxt" v-if="matchInfoShow && roundType === 'knockout'" type="border-card" style="margin-top: 15px"
      v-model="currentMatchInfoIndex" @tab-click="getKnockoutMatchInfo('select')">
      <el-tab-pane
        v-for="(item, i) in knockoutMatchInfo"
        :label="item.turnName"
        :name="i + ''"
        :key="i"
      >
        <template slot="label">
          <div class="usual-tab-card" :class="'usual-tab-card' + item.turnType">
            {{ item.turnName }}
          </div>
        </template>
        <el-row :gutter="20" class="tab-headview">
          <el-col
            :span="18"
            style="font-size: 20px"
            v-if="item.turnType !== '-110'"
          >
            <span style="margin-left: 30px; color: #67c23a"
              >预晋级人数:{{ statisticsInfo.setPassNum }}</span
            >
            <span style="margin-left: 30px; color: #67c23a"
              >已晋级人数:{{ statisticsInfo.passedNum }}</span
            >
            <span style="margin-left: 30px; color: #e6a23c"
              >本轮人数:{{ statisticsInfo.curNum }}</span
            >
            <span style="margin-left: 30px; color: #e6a23c"
              >本轮晋级:{{ statisticsInfo.curPass }}</span
            >
            <span style="margin-left: 30px; color: #e6a23c"
              >本轮淘汰:{{ statisticsInfo.curOut }}</span
            >
            <span style="margin-left: 30px; color: #e6a23c"
              >本轮待比赛:{{ statisticsInfo.curWait }}</span
            >
          </el-col>
          <el-col
            :span="18"
            style="font-size: 20px"
            v-if="item.turnType === '-110'"
          >
            <span style="margin-left: 20px; color: #67c23a"
              >参赛人数:{{ statisticsInfo.curNum }}</span
            >
            <span style="margin-left: 20px; color: #67c23a"
              >晋级人数:{{ statisticsInfo.curPass }}</span
            >
            <span style="margin-left: 20px; color: #67c23a"
              >淘汰人数:{{ statisticsInfo.curOut }}</span
            >
          </el-col>
          <el-col :span="3" style="text-align: right">
            <el-button
              type="danger"
              size="medium"
              @click="handleToSetFallback(item)"
              style="width: 100%"
              :disabled="examRoomInfo.isIng === 1"
              v-if="
                Number(currentMatchInfoIndex) ===
                  Number(knockoutMatchInfo.length - 1) && knockoutMatchInfo.length > 1
              "
              >比赛回退</el-button
            >
          </el-col>
          <el-col
            :span="3"
            style="text-align: right"
            v-if="item.turnType !== '-110'"
          >
            <el-button
              type="primary"
              size="medium"
              @click="showSetPromotionDialog(item)"
              style="width: 100%"
              v-if="item.setPromotionUnEnable !== '1'"
              >设置晋级</el-button
            >
          </el-col>
        </el-row>
        <el-table
          header-cell-class-name="tableHeaderStyle"
          :data="item.players"
          border
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column
            label="学校"
            prop="schoolName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="年级"
            prop="grade"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            label="座位号"
            width="120"
            prop="player_match_number"
            align="center"
          ></el-table-column>
          <el-table-column
            label="选手姓名"
            width="180"
            prop="name"
            align="center"
          >
            <template scope="scope">
              <span class="nationalnum"
                >{{ scope.row.name }}
                {{ scope.row.countryReserve === true ? "(保留)" : "" }}</span
              >
            </template></el-table-column
          >
          <!-- <el-table-column
            label="比赛号码牌"
            width="120"
            prop="nationalnum"
            align="center"
          >
            <template slot-scope="scope">
              <div class="nationalnum">{{ scope.row.nationalnum }}</div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="晋级"
            v-if="item.turnType !== '-110'"
            width="160px"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                border
                label="晋级"
                size="small"
                :class="scope.row.promoted === '1' ? 'promoted-btn' : ''"
                v-model="scope.row.promoted"
                true-label="1"
                false-label=""
                :key="scope.row.matchPlayerId"
                @change="upDateStatisticsInfo(item)"
              >
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="淘汰"
            v-if="item.turnType !== '-110'"
            width="160px"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                border
                label="淘汰"
                size="small"
                :class="scope.row.promoted === '-1' ? 'eliminate-btn' : ''"
                v-model="scope.row.promoted"
                true-label="-1"
                false-label=""
                :key="scope.row.matchPlayerId"
                :style="{ '--fill-color': '#F56C6C' }"
                @change="upDateStatisticsInfo(item)"
              >
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="晋级状态"
            v-if="item.turnType === '-110' && queryForm.ibcRound !== '5'"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.promoted === '1'"
                >晋级</el-tag
              >
              <el-tag type="danger" v-else-if="scope.row.promoted === '-1'"
                >淘汰</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            label="排名"
            v-if="
              item.turnType === '-110' && queryForm.matchType !== 14
            "
            prop="score_promoted"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div :class="scope.row.promoted === '1' ? 'rank-success' : ''">
                {{ scope.row.score_promoted }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <!-- 三四五轮设置晋级人数的Dialog -->
    <el-dialog title="设置晋级人数" :visible.sync="setPromotionNumberDialogVisable" width="30%">
      <el-form label-width="80px">
        <el-form-item label="晋级人数">
          <!-- :disabled="setPromotionNumberEnable" -->
          <el-input type="number" v-model="promotionNum"  placeholder="请输入晋级人数"></el-input>
        </el-form-item>
      </el-form>
      <!-- v-if="!setPromotionNumberEnable" -->
      <span slot="footer" class="dialog-footer" >
        <el-button type="primary" @click="setPromotionNumber()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 三四五轮设置晋级的Dialog -->
    <el-dialog title="设置晋级" :visible.sync="setPromotionDialogVisible" width="50%">
      <div style="font-size: 18px">
        本轮为：{{ currentroundName }} ，参赛人数：{{ currentTurnUserNum }}
      </div>
      <div style="color: #f56c6c; font-size: 18px; margin-top: 40px">
        本轮淘汰选手：{{ setPromotionTipsOut.length }} 人
      </div>
      <el-row>
        <el-col
          v-for="(item, index) in setPromotionTipsOut"
          :key="index"
          :span="6"
        >
          <span style="color: #f56c6c"
            ><span style="font-weight: bold">{{ item.player_match_number }}</span
            >号-{{ item.name }}</span
          >
        </el-col>
      </el-row>
      <div style="color: #67c23a; margin-top: 40px; font-size: 18px">
        本轮晋级选手：{{ setPromotionTipsPass.length }} 人, {{ rankTitleStrJ }}
      </div>
      <el-row>
        <el-col
          v-for="(item, index) in setPromotionTipsPass"
          :key="index"
          :span="6"
        >
          <span style="color: #67c23a"
            ><span style="font-weight: bold">{{ item.player_match_number }}</span
            >号-{{ item.name }}</span
          >
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="promotTied()" v-if="setPromotionTipsOut.length === 0">并列晋级</el-button>
        <el-button @click="setPromotionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setPromotion()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 三四五轮查看实时排名的Dialog -->
    <el-dialog title="实时排名" :visible.sync="showRealTimeRankingDialogVisable" width="60%">
      <el-table header-cell-class-name="tableHeaderStyle" :data="rankInfo" border>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="座位号" prop="match_num"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="性别" prop="gender" :formatter="genderFormatter"></el-table-column>
        <el-table-column label="学校" prop="school_name"></el-table-column>
        <el-table-column label="年级" prop="grade"></el-table-column>
        <el-table-column label="晋级状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.passed === 0" size="mini"
              >未晋级</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.passed === 1"
              size="mini"
              >晋级</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.passed === -1"
              size="warning"
              >待比赛</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="排名1" prop="promoted" align="center" v-if="queryForm.matchType !== 14">
          <template slot-scope="scope">
            <div :class="scope.row.passed === 1 ? 'rank-success' : ''">
              {{ scope.row.promoted }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 添加考生信息的dialog -->
    <el-dialog title="添加考生" :visible.sync="addExamineeDialogVisible" @close="closeAddExamineeDialog" width="70%">
      <el-form :model="addInfoForm" label-width="150px">
        <el-table header-cell-class-name="tableHeaderStyle" ref="addInfoTableRef" @select="handleSelectionChange"
          :data="examineeDataList" border stripe>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name"></el-table-column>
          <el-table-column label="国籍" prop="country"></el-table-column>
          <el-table-column label="性别" prop="gender" :formatter="genderFormatter"></el-table-column>
          <el-table-column label="学校" prop="schoolName"></el-table-column>
          <el-table-column label="组别" prop="enrollmentLevel" :formatter="groupFormatter"></el-table-column>
          <el-table-column label="联系方式" prop="phone"></el-table-column>
          <el-table-column label="邮箱" prop="email"></el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addExamineeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAddExamineeClick">保 存</el-button>
      </span>
    </el-dialog>
     <!-- 重置所有轮次 -->
     <el-dialog
      title="重置所有轮次"
      :visible.sync="resetAllRoundsDialogVisable"
      width="40%"
    >
      <el-form label-width="80px">
        <el-form-item label="输入密码">
          <el-input type="text" v-model="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetAllRounds()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $common from '@/methods/common.js'
import $indexedDB from '@/methods/indexedDB.js'
import {
  getIbcRoomList, getIbcRoomInfo, getIbcTwoThreeMatchList, setMatchPlayer, getIbcSignUpUser,
  saveIbcMatchScoreList, deleteIbcTwoThreeMatchUser, getPromotionRound, setPromotionNum, ibcResetMatch,
  setPromotionPlayer, apposition, getRealTimeRanking, addIbcTwoThreeMatch, getPromotionNumber, resettingRound,
  finishTheGame, queryIbcSeason
} from '@/http/api'
export default {
  data () {
    return {
      resetAllRoundsDialogVisable: false,
      password: '',
      rankTitleStrJ: '',
      rankTitleStr: '',
      createdInit: true,
      examineeDataList: [],
      dict_matchType: this.$userInfo.getDataList(this, 'ibc_matchType').then(value => { this.dict_matchType = value }),
      addInfoForm: {
        activityType: this.$chnEngStatusCode.ibcActivityType,
        enrollmentId: '',
        ibcSession: '',
        ibcSeason: '',
        ibcRound: '',
        hallId: '',
        hallLevel: ''
      },
      roundType: 'point', // 区分是积分制（point）还是淘汰制（knockout）
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      dict_grade: this.$userInfo.getGradeData(),
      isChange: false,
      matchInfoShow: false,
      total: 0,
      gradeName: '未设置',
      matchTypeClass: '',
      statisticsForm: {
        matchId: null,
        hallId: null
      },
      queryForm: {
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcSession: this.$ibcManager.ibcSession,
        ibcSeason: this.$route.query.ibcSeason,
        ibcRound: '2',
        hallId: null,
        id: null,
        levels: 0,
        matchType: null
      },
      totalScore: -1,
      examRoomList: [],
      examMatchInfo: [],
      queryFormRule: {
        hallId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      examRoomInfo: {},
      addExamineeForm: {
        phone: ''
      },
      addExamineeDialogVisible: false,
      // addExamineeFormRules: {
      //   phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }]
      // },
      knockoutMatchInfo: [],
      rankInfo: [], // 实时排名List
      deleteExamineeData: {},
      promotionNum: null, // 晋级人数
      // tieforPromotion: false, // 是否显示并列晋级按钮
      setPromotionNumberDialogVisable: false, // 设置晋级人数弹窗
      setPromotionNumberEnable: false, // 是否允许修改晋级人数
      showRealTimeRankingDialogVisable: false, // 查看实时排名弹窗
      currentMatchInfoIndex: '0', // 四五轮比赛轮次
      statisticsInfo: {
        activityType: this.$chnEngStatusCode.ibcActivityType,
        promotionNum: '',
        promotedNum: '',
        curNum: '',
        curPass: '',
        curOut: '',
        curWait: ''
      },
      setPromotionDialogVisible: false, // 设置晋级弹窗
      setPromotionTipsOut: [], // 本轮淘汰选手
      setPromotionTipsPass: [], // 本轮晋级选手
      setPromotionData: [] // 所有选手信息
    }
  },
  computed: {
    $common () { return $common }, // 公共方法
    currentroundName () {
      if (this.knockoutMatchInfo && this.knockoutMatchInfo.length !== 0 && this.knockoutMatchInfo[this.currentMatchInfoIndex] !== undefined) {
        return this.knockoutMatchInfo[this.currentMatchInfoIndex].turnName
      }
      return ''
    },
    currentTurnUserNum () {
      console.log('this.knockoutMatchInfo', this.knockoutMatchInfo)
      if (this.knockoutMatchInfo && this.knockoutMatchInfo.length !== 0 && this.knockoutMatchInfo[this.currentMatchInfoIndex].players && this.knockoutMatchInfo[this.currentMatchInfoIndex] !== undefined) {
        return this.knockoutMatchInfo[this.currentMatchInfoIndex].players.length
      }
      return ''
    },
    showBackButton () {
      var schduleArray = []
      for (let index = 0; index < this.knockoutMatchInfo.length; index++) {
        const element = this.knockoutMatchInfo[index]
        if (element.roundType !== 9) {
          schduleArray.push(element)
        }
      }
      return (Number(this.currentMatchInfoIndex) === schduleArray.length - 1) && (Number(this.currentMatchInfoIndex) !== 0)
    },
    showSetPromotionButton () {
      var schduleArray = []
      for (let index = 0; index < this.knockoutMatchInfo.length; index++) {
        const element = this.knockoutMatchInfo[index]
        if (element.roundType !== 9) {
          schduleArray.push(element)
        }
      }
      return (Number(this.currentMatchInfoIndex) === schduleArray.length - 1)
      // v-if="dataItem.setPromotionUnEnable !== '1' && dataItem.roundType !== 9"
    },
    tieforPromotion () {
      if (this.knockoutMatchInfo && this.knockoutMatchInfo.length !== 0 && this.knockoutMatchInfo[this.currentMatchInfoIndex]) {
        return this.knockoutMatchInfo[this.currentMatchInfoIndex].roundType === 2 || this.knockoutMatchInfo[this.currentMatchInfoIndex].roundType === 5 || this.knockoutMatchInfo[this.currentMatchInfoIndex].roundType === 6 || this.knockoutMatchInfo[this.currentMatchInfoIndex].roundType === 7
      }
      return ''
    }
  },
  created () {
    this.getQueryIbcSeason()
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.turnType !== '-110') {
        return row.promoted === '-1' ? 'warning-row' : (row.promoted === '1' ? 'success-row' : '')
      }
      return ''
    },
    goBack () {
      this.$router.back()
    },
    genderFormatter (data) {
      if (data.gender === 0) {
        return '女'
      }
      return '男'
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === Number(data.enrollmentLevel)) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    hallGroupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === Number(data.hallLevel)) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    hallMatchClassFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_matchType) {
        if (Object.hasOwnProperty.call(this.dict_matchType, key)) {
          const element = this.dict_matchType[key]
          if (element.dictValue === data.matchType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // ========== 获取未提交数据 ==========
    async getUncommitData () {
      if (this.createdInit === false) {
        this.getGameInfo()
      } else {
        var dbkey = 'ibcCache' + this.queryForm.hallId
        const dataGet = await $indexedDB.get(dbkey, 'signInData').catch(() => {
          // 获取缓存失败
          this.getGameInfo()
        })
        if (!dataGet) { // 获取缓存成功 但是没有本地缓存
          this.getGameInfo()
        } else {
          this.createdInit = false
          this.matchInfoShow = true
          this.examMatchInfo = dataGet.data
          this.total = dataGet.data.length
        }
      }
    },
    // 缓存成绩
    async saveScoreCache (data) {
      // 只留存二三轮的4轮积分制成绩
      if (this.roundType === 'point') {
        var dbkey = 'ibcCache' + this.queryForm.hallId
        const dataSet = await $indexedDB.set({
          type: dbkey,
          data: data
        }, 'signInData').catch(() => {
          this.$common.closeLoading() // 关闭加载
        })
        if (!dataSet) return
        this.$common.closeLoading() // 关闭加载
      }
    },
    // 显示四五轮设置晋级人数弹窗获取晋级人数和是否允许修改，比赛开始不能修改晋级人数
    showSetPromotionNumberDialog () {
      if (this.examRoomInfo.promotionNum && this.examRoomInfo.promotionNum !== 0) {
        this.promotionNum = this.examRoomInfo.promotionNum
        this.setPromotionNumberEnable = true
      } else {
        this.setPromotionNumberEnable = false
      }
      if (this.queryForm.ibcRound === '5') {
        this.setPromotionNumber()
      } else {
        this.setPromotionNumberDialogVisable = true
      }
    },
    // 设置参赛人员
    setMatchPlayer () {
      var deleteForm = {
        hallId: this.queryForm.hallId
      }
      setMatchPlayer(deleteForm).then((res) => {
        this.$message.success('设置成功')
        this.getRoundInfoData()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    isValidityStartTime (e) {
      const currentTime = new Date()
      const startTime = new Date(e)
      if (startTime < currentTime) {
        return false
      } else {
        return true
      }
    },
    // 获取默认届和赛季
    getQueryIbcSeason () {
      queryIbcSeason().then((res) => {
        const newObj = Object.entries(res.data).reduce((obj, [key, value]) => {
          obj[key] = value.toString()
          return obj
        }, {})
        console.log('newObj', newObj)
        this.queryForm.ibcSession = newObj.ibcSession
        this.getExamRoomInfo()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取考场列表
    getExamRoomInfo () {
      getIbcRoomList(this.queryForm).then((res) => {
        this.examRoomList = res.data
        this.getRoundInfoData()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 根据轮次区分后续逻辑
    getRoundInfoData () {
      if (this.queryForm.ibcRound === '3' || this.queryForm.ibcRound === '4' || this.queryForm.ibcRound === '5') {
        this.roundType = 'knockout'
      } else {
        this.roundType = 'point'
      }
      this.getMatchInfo()
    },
    // 获取考场信息
    getMatchInfo () {
      this.$refs.queryFormRef.validate(async valid => {
        if (!valid) return
        getIbcRoomInfo(this.queryForm).then((res) => {
          this.examRoomInfo = res.data
          this.queryForm.matchType = res.data.matchType
          this.addInfoForm.hallLevel = res.data.hallLevel
          this.statisticsInfo.promotionNum = res.data.promotionNum
          this.gradeName = this.hallGroupFormatter(res.data)
          this.matchTypeClass = this.hallMatchClassFormatter(res.data)
          this.getUncommitData()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 获取二三、四五轮考场成员列表信息
    getGameInfo (type) {
      if (this.roundType === 'point') {
        this.$refs.queryFormRef.validate(async valid => {
          if (!valid) return
          getIbcTwoThreeMatchList(this.queryForm).then((res) => {
            if (type === 'staging') {
              this.addExamineeDialogVisible = true
            }
            this.createdInit = false
            this.examMatchInfo = res.data
            this.saveScoreCache(res.data)
            this.total = res.data.length
          }).catch((err) => {
            console.log('err', err)
          })
        })
      } else if (this.roundType === 'knockout') {
        this.getKnockoutMatchInfo()
      }
      this.matchInfoShow = true
    },
    // 添加考生点击事件（显示添加考生弹窗）
    addExamineeClick () {
      if (this.isChange === true) {
        this.$confirm('是否先保存当前改动信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.saveExamInfoData('staging')
        }).catch(() => {
          this.getAddExamineeListData()
          // this.getGameInfo('staging')
        })
      } else {
        this.getAddExamineeListData()
        // this.addExamineeDialogVisible = true
      }
    },
    getAddExamineeListData () {
      this.isChange = false
      this.addInfoForm.ibcSession = this.queryForm.ibcSession
      this.addInfoForm.ibcSeason = this.queryForm.ibcSeason
      this.addInfoForm.ibcRound = this.queryForm.ibcRound
      this.addInfoForm.hallId = Number(this.queryForm.hallId)
      this.addInfoForm.hallLevel = this.examRoomInfo.hallLevel
      this.getExamineeList()
    },
    // 筛选条件改变
    selectChanged () {
      this.queryForm.hallId = ''
      this.examMatchInfo = []
      this.total = 0
      this.matchInfoShow = false
      this.getExamRoomInfo()
    },
    // 保存二三轮成绩点击事件
    saveExamInfoData (type) {
      if (type === 'save') {
        for (let index = 0; index < this.examMatchInfo.length; index++) {
          const element = this.examMatchInfo[index]
          var message = ''
          if (element.roundOne === '-1') {
            message = '请选择"' + element.name + '"  round1  答题是否正确'
            return this.$message.error(message)
          }
          if (element.roundTwo === '-1') {
            message = '请选择' + element.name + 'round2答题是否正确'
            return this.$message.error(message)
          }
          if (element.roundThree === '-1') {
            message = '请选择' + element.name + 'round3答题是否正确'
            return this.$message.error(message)
          }
          if (element.roundFour === '-1') {
            message = '请选择' + element.name + 'round4答题是否正确'
            return this.$message.error(message)
          }
        }
      }
      saveIbcMatchScoreList(this.examMatchInfo).then((res) => {
        this.isChange = false
        if (type === 'staging') {
          this.addExamineeDialogVisible = true
        } else if (type === 'delete') {
          this.confirmDeleteCateInfo()
        } else {
          this.$alert('保存成功', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.event_delCacheData()
              this.getGameInfo()
            }
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // ========== 事件: 删除缓存数据 ==========
    async event_delCacheData () {
      var dbkey = 'ibcCache' + this.queryForm.hallId
      const dataGet = await $indexedDB.del(dbkey, 'signInData').catch(() => {
        return Promise.reject(new Error('删除缓存数据-失败'))
      })
      return new Promise((resolve, reject) => {
        if (!dataGet) resolve()
        resolve()
      })
    },
    // 成绩单选框点击事件(计算成绩)
    onPassClickChange (scope) {
      this.isChange = true
      const rowData = scope.row
      this.totalScore = 0
      if (rowData.roundOne === '1') {
        this.totalScore += 5
      }
      if (rowData.roundTwo === '1') {
        this.totalScore += 8
      }
      if (rowData.roundThree === '1') {
        this.totalScore += 10
      }
      if (rowData.roundFour === '1') {
        this.totalScore += 12
      }
      if (rowData.roundOne !== '-1' && rowData.roundTwo !== '-1' && rowData.roundThree !== '-1' && rowData.roundFour !== '-1') {
        rowData.score = this.totalScore
        this.examMatchInfo[scope.index] = rowData
      }
      this.saveScoreCache(this.examMatchInfo)
    },
    // 考场成绩信息保存点击事件
    examInfoSaveClicked () {
      this.saveExamInfoData('save')
    },
    // 判断删除前是否有数据未保存
    judgeDeleteExamUserChange (deleteData) {
      if (this.isChange === true) {
        this.$confirm('是否先保存当前改动信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.deleteExamineeData = deleteData
          this.saveExamInfoData('delete')
        }).catch(() => {
          this.isChange = false
          this.showDeleteExamUserDialog(deleteData)
        })
      } else {
        this.showDeleteExamUserDialog(deleteData)
      }
    },
    copy () {
      var input = document.createElement('input') // 创建input标签
      input.value = this.examRoomInfo.meetingNum // 将input的值设置为需要复制的内容
      document.body.appendChild(input) // 添加input标签
      input.select() // 选中input标签
      document.execCommand('copy') // 执行复制
      this.$message.success('复制成功') // 成功提示信息
      document.body.removeChild(input) // 移除input标签
    },
    showDeleteExamUserDialog (deleteData) {
      this.$confirm('是否将该考生删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteExamineeData = deleteData
        // 确认删除
        // this.saveExamInfoData('delete')
        this.confirmDeleteCateInfo()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 确认删除
    confirmDeleteCateInfo () {
      var deleteForm = {
        id: this.deleteExamineeData.id
      }
      deleteIbcTwoThreeMatchUser(deleteForm).then((res) => {
        this.$message.success('删除成功')
        this.getMatchInfo()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取3/4/5轮列表信息
    getKnockoutMatchInfo (flag) {
      this.$refs.queryFormRef.validate(async valid => {
        if (!valid) return
        var potFormData = JSON.parse(JSON.stringify(this.queryForm))
        potFormData.id = null
        potFormData.turns = null
        potFormData.levels = 0
        getPromotionRound(potFormData).then((res) => {
          console.log('getPromotionRound____', res.data)
          this.knockoutMatchInfo = res.data
          if (flag !== 'select') {
            this.currentMatchInfoIndex = this.knockoutMatchInfo.length - 1 + ''
          }
          this.matchInfoShow = true
          console.log('this.currentMatchInfoIndex', this.currentMatchInfoIndex)
          this.statisticsForm.hallId = this.queryForm.hallId
          if (this.knockoutMatchInfo.length !== 0) {
            this.statisticsForm.matchId = this.knockoutMatchInfo[this.currentMatchInfoIndex].id
          }
          if (this.knockoutMatchInfo.length > 0) {
            this.upDateStatisticsInfo(this.knockoutMatchInfo[this.currentMatchInfoIndex])
          }
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 更新4/5轮状态人数信息
    upDateStatisticsInfo (data) {
      this.statisticsInfo.setPassNum = data.promotedNum
      this.statisticsInfo.passedNum = data.currentPromotedNum
      this.statisticsInfo.curNum = 0
      this.statisticsInfo.curPass = 0
      this.statisticsInfo.curOut = 0
      this.statisticsInfo.curWait = 0
      data.players.forEach(element => {
        this.statisticsInfo.curNum++
        if (element.promoted === '1') {
          this.statisticsInfo.curPass++
        } else if (element.promoted === '-1') {
          this.statisticsInfo.curOut++
        } else {
          this.statisticsInfo.curWait++
        }
      })
      console.log('this.statisticsInfo', this.statisticsInfo)
    },
    // 3/4/5轮设置晋级人数保存
    setPromotionNumber () {
      this.queryForm.promotionNumber = this.queryForm.ibcRound === '5' ? 1 : this.promotionNum
      if (this.queryForm.promotionNumber > 0) {
        setPromotionNum(this.queryForm).then((res) => {
          this.$message.success('设置成功')
          this.getMatchInfo()
          this.setPromotionNumberDialogVisable = false
        }).catch((err) => {
          console.log('err', err)
        })
      } else {
        return this.$message.warning('晋级人数不能小于1人')
      }
    },

    handleToSetPromotionData (data) {
      console.log('data', data)
      // 检查是否所有选手都设置了晋级状态
      var temp = []
      data.players.forEach(element => {
        if (element.promoted === '') {
          temp.push(element)
        }
      })
      if (temp.length > 0) {
        var tempStr = ''
        temp.forEach(element => {
          tempStr += `${element.nationalnum}号-${element.name}`
        })
        return this.$alert(tempStr, '存在选手未设置晋级', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true
        })
      }
      // 再次确认本轮晋级情况
      // 初始化数据
      this.setPromotionTipsOut = []
      this.setPromotionTipsPass = []
      data.players.forEach(element => {
        if (element.promoted === '1') {
          this.setPromotionTipsPass.push(element)
        }
        if (element.promoted === '-1') {
          this.setPromotionTipsOut.push(element)
        }
      })
      this.setPromotionData = data
      this.getEliminationRankingInformation()
      // this.setPromotionDialogVisible = true
    },
    // 获取淘汰排名信息
    getEliminationRankingInformation () {
      var matchPlayerIds = '' // 晋级比赛选手id
      this.setPromotionData.players.forEach(element => {
        if (element.promoted === '1') {
          matchPlayerIds += element.matchPlayerId + ','
        }
      })
      if (matchPlayerIds.length > 0) { // 去掉末尾逗号
        matchPlayerIds = matchPlayerIds.substring(0, matchPlayerIds.length - 1)
      }
      this.queryForm.matchPlayerIds = matchPlayerIds
      this.queryForm.turnId = this.setPromotionData.turnId
      getPromotionNumber(this.queryForm).then((res) => {
        if (res.code === 200) {
          if (res.data.t) {
            this.rankTitleStr = '获得名次：第' + res.data.t + '名'
          } else {
            this.rankTitleStr = ''
          }
          if (res.data.j) {
            this.rankTitleStrJ = '获得名次：第' + res.data.j + '名'
          } else {
            this.rankTitleStrJ = ''
          }
        }
        this.setPromotionDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 4/5轮设置晋级弹窗
    showSetPromotionDialog (data) {
      this.handleToSetPromotionData(data)
    },
    handleToSetFallback (rowData) {
      this.setPromotionData = rowData
      console.log('rowData', rowData)
      this.$confirm('确定回退?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.handleToSetQueryForm()
        this.queryForm.turnId = rowData.turnId
        console.log('this.queryForm', this.queryForm)
        ibcResetMatch(this.queryForm).then((res) => {
          this.$message.success('已回退')
          this.getKnockoutMatchInfo()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    handleToSetQueryForm () {
      var matchPlayerIds = '' // 晋级比赛选手id
      if (this.setPromotionData.players) {
        this.setPromotionData.players.forEach(element => {
          console.log('element', element)
          if (element.promoted === '1') {
            matchPlayerIds += element.matchPlayerId + ','
          }
        })
      }
      if (matchPlayerIds.length > 0) { // 去掉末尾逗号
        matchPlayerIds = matchPlayerIds.substring(0, matchPlayerIds.length - 1)
      }
      this.queryForm.id = this.knockoutMatchInfo[this.currentMatchInfoIndex].id
      this.queryForm.level = this.knockoutMatchInfo[this.currentMatchInfoIndex].level
      this.queryForm.matchPlayerIds = matchPlayerIds
      this.queryForm.turns = this.setPromotionData.turns
    },
    // 4/5轮设置并列晋级弹窗提示
    promotTied () {
      this.$confirm('并列晋级将由“本轮晋级选手”并列排名晋级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setPromotion('promotTied')
      })
    },
    // 4/5轮晋级确认
    setPromotion (flag) {
      this.handleToSetQueryForm()
      console.log('this.queryForm', this.queryForm)
      // var formData = {
      //   matchType: 14,
      //   matchTypeName: '全国总决赛（有组别）',
      //   districtId: '',
      //   levels: 0,
      //   matchPlayerIds: this.queryForm.matchPlayerIds,
      //   turnId: 11013,
      //   season: '2022~2023'
      // }
      if (flag === 'promotTied') {
        // this.queryForm
        apposition(this.queryForm).then((res) => {
          this.$curUtils.closeLoading(this)
          this.$message.success('设置晋级成功')
          this.setPromotionDialogVisible = false
          this.getKnockoutMatchInfo()
        }).catch((err) => {
          console.log('err', err)
        })
      } else {
        setPromotionPlayer(this.queryForm).then((res) => {
          this.$curUtils.closeLoading(this)
          this.$message.success('设置晋级成功')
          this.setPromotionDialogVisible = false
          this.getKnockoutMatchInfo()
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 4/5轮比赛结束点击提示
    matchOver () {
      this.$confirm('比赛结束将由“已晋级选手”和“本轮晋级选手”直接生成晋级名单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setPromotion('matchOver')
      })
    },
    // 4/5轮查看实时排名点击事件
    showRankInfoDialog () {
      getRealTimeRanking(this.queryForm).then((res) => {
        this.rankInfo = res.data
        this.showRealTimeRankingDialogVisable = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 重置所有轮次
    resetAllRounds () {
      // if (this.password !== '1qazxsw2.') {
      //   return this.$message.error('密码错误！')
      // }
      this.queryForm.password = this.password
      resettingRound(this.queryForm).then((res) => {
        this.resetAllRoundsDialogVisable = false
        this.getMatchInfo()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取未分配考生
    getExamineeList () {
      this.addInfoForm.level = this.addInfoForm.hallLevel
      getIbcSignUpUser(this.addInfoForm).then((res) => {
        this.addExamineeDialogVisible = true
        this.examineeDataList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 选择未分配考生
    handleSelectionChange (val, row) {
      this.addInfoForm.enrollmentId = row.enrollmentId
      this.$refs.addInfoTableRef.clearSelection()
      this.$refs.addInfoTableRef.toggleRowSelection(row, true)
    },
    // 选择未分配考生弹窗关闭事件
    closeAddExamineeDialog () {
      this.addInfoForm.enrollmentId = ''
      this.$refs.addInfoTableRef.clearSelection()
    },
    // 保存新增考生
    async saveAddExamineeClick () {
      addIbcTwoThreeMatch(this.addInfoForm).then((res) => {
        this.addExamineeDialogVisible = false
        this.$message.success('添加成功')
        this.getRoundInfoData()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 比赛结束
    finishKnockoutMatchInfo () {
      console.log('this.queryForm', this.queryForm)
      this.$confirm('比赛结束将确认晋级选手，并不可再进行回退, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        finishTheGame(this.queryForm).then((res) => {
          this.$message.success('设置成功！')
          this.getRoundInfoData()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    nationalnumFormatter (num) {
      return this.$xcUtils.addZero(num)
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.hallLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.el-tab-pane {
  height: calc(100vh - 110px);
  overflow-y: auto;
}

.el-table {
  margin-top: 56px;
  z-index: 99;
}

.tab-headview {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
  padding: 10px 0px;
}

.span_row {
  display: flex;
  flex-direction: row;
}

.card-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.scoreClass {
  font-size: 16px;
  color: green;
}

.el-row {
  margin: 10px 0;
}

.exam_span_class {
  font-size: 14px;
}

::v-deep .el-table__header-wrapper .el-checkbox {
  display: none;
}

/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  border-right-color: transparent;
  border-left-color: transparent;
  height: 50px;
  line-height: 56px;
  color: #fff;
  font-size: 18px;
  border-radius: 20px 20px 0 0;

  .usual-tab-card {
    background: #409eff;
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
