/*
$common.openLoading() // 开启加载
$common.closeLoading() // 关闭加载
$common.message(text, type = 'info') // 提示信息 success / error / warning / info
$common.confirm(type = 'del', confirm, cancel) // 确认提示框

$common.decimalMath(num1, math, num2) // 小数运算
$common.arrFindIndex(arr, val, valKey) // 数组找下标
$common.arrFindEl(arr, val, valKey, labKey) // 数组找元素

$common.dateFormat(date, format) // 日期格式 'YYYY-MM-DD hh:mm:ss:SS 星期W'
$common.getDate(type, num, time) // 获取日期
$common.dateInterval(startTime, endtime, type = 'M') // 日期时间差

$common.download(url, name) // 下载文件

$common.limitTime(startStr, startTime, endStr, endTime, comeback) // 限制时间
$common.getMenuName($route, level) // 获取n级菜单名
*/

import Vue from 'vue'

export default new Vue({
  data () {
    return {
      loading: null // 加载
    }
  },
  methods: {
    // ==================== 开启加载 ====================
    openLoading () {
      this.loading = this.$loading({
        lock: true,
        text: '拼命加载中……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // ==================== 关闭加载 ====================
    closeLoading () {
      this.loading && this.loading.close()
    },
    // // ==================== 提示信息 ====================
    // type = success / error / warning / info, 输入'<br>'换行
    message (text, type = 'info') {
      const h = this.$createElement
      const arr = []
      if (text.indexOf('<br>') > -1) {
        text.split('<br>').forEach(b => {
          arr.push(h('p', null, b))
        })
      } else {
        arr.push(h('p', null, text))
      }
      this.$message({
        message: h('div', { style: { marginRight: '24px' } }, arr),
        type,
        showClose: true // 显示关闭按钮
      })
    },
    // // ====================== 确认提示框 ====================
    // confirm (type = 'del', confirm, cancel) {
    //   const info = {
    //     del: { text: '此操作将永久删除该条数据，是否继续？' },
    //     batchDel: { text: '此操作将永久删除选中数据，是否继续？' },
    //     submit: { text: '您选择提交此记录，提交后将不能更改，是否继续？', confirmBtnText: '继续' },
    //     lesmony: { text: '当前合同收费未完成，是否继续终止？' },
    //     equmony: { text: '该操作将终止合同，是否继续终止？' },
    //     submitCon: { text: '该操作将结束合同，是否结束？', confirmBtnText: '继续' }
    //   }
    //   this.$confirm(info[type].text, (info[type].title || '提示'), {
    //     confirmButtonText: info[type].confirmBtnText || '确定',
    //     cancelButtonText: info[type].cancelBtnText || '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     confirm && typeof confirm === 'function' && confirm()
    //   }).catch(() => {
    //     cancel && typeof cancel === 'function' && cancel()
    //   })
    // },
    // ====================== 小数运算 ======================
    // math[必填]: 运算符(+ - * / %) / num1[必填]: 数字1 / num2: 数字2
    decimalMath (num1, math, num2) {
      let pow = 1
      const arr = [num1 + '', num2 + '']
      arr.forEach(b => {
        const n = b.indexOf('.') ? b.length - b.indexOf('.') - 1 : 0
        pow = n > pow ? n : pow
      })
      const len = Math.pow(10, pow)
      num1 = Number(num1) * len
      num2 = Number(num2) * len
      let result = 0
      math = math.trim() // 删除字符串两端的空白字符
      if (math === '+') result = (num1 + num2) / len
      if (math === '-') result = (num1 - num2) / len
      if (math === '*') result = num1 * num2 / len / len
      if (math === '/') result = num1 / num2
      if (math === '%') result = num1 % num2 / len
      // console.log('小数运算', result)
      return result
    },
    // ==================== 数组找下标 ====================
    // arr[必填]: 数组 / val[必填]: 当前值 / valKey: 当前值键名
    arrFindIndex (arr, val, valKey) {
      if (!arr) arr = []
      return arr.findIndex(b => b === val || b + '' === val || b[valKey] === val || b[valKey] + '' === val)
    },
    // ==================== 数组找元素 ====================
    // arr[必填]: 数组 / val[必填]: 当前值 / valKey: 当前值键名 / labKey: 目标值键名
    arrFindEl (arr, val, valKey, labKey) {
      if (!arr) arr = []
      const el = arr.find(b => b === val || b + '' === val || b[valKey] === val || b[valKey] + '' === val)
      return el ? (labKey ? el[labKey] : el) : null
    },
    // ==================== 日期格式 'YYYY-MM-DD hh:mm:ss:SS 星期W' ====================
    // H+: 也是时，但只显示(1-12) / Q+: 季度
    dateFormat (date, format) {
      const week = ['\u65e5', '\u4e00', '\u4e8c', '\u4e09', '\u56db', '\u4e94', '\u516d']
      const str = {
        'M+': date.getMonth() + 1, // 月
        'D+': date.getDate(), // 日
        'W+': week[date.getDay()], // 星期/周
        'H+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 时
        'h+': date.getHours(), // 时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'S+': date.getMilliseconds(), // 毫秒
        'Q+': Math.floor((date.getMonth() + 3) / 3) // 季度
      }
      let fmt = format
      if (/(Y+)/.test(fmt)) { // 年
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (const k in str) { // 循环替换匹配值
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str[k] : ('00' + str[k]).substr(('' + str[k]).length))
        }
      }
      return fmt
    },
    // ==================== 获取日期 (返回原日期格式) ====================
    getDate (type, num, time) {
      let date = time ? new Date(time) : new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      // let day = date.getDate()
      if (type === 'Y') { // 设置某年
        date.setFullYear(num)
      } else if (type === 'M') { // 设置某月 0-11
        date.setMonth(num - 1)
      } else if (type === 'D') { // 设置某日 1-31
        date.setDate(num)
      } else if (type === 'beforeY') { // 年前
        date.setFullYear(year - num)
      } else if (type === 'afterY') { // 年后
        date.setFullYear(year + num)
      } else if (type === 'beforeM') { // 月前
        if (num < month) {
          date.setMonth(month - num - 1)
        } else {
          const numY = parseInt((num - month) / 12)
          const numM = 12 - (num - month) % 12
          date.setFullYear(year - numY - 1)
          date.setMonth(numM - 1)
        }
      } else if (type === 'afterM') { // 月后
        if (num <= 12 - month) {
          date.setMonth(month + num - 1)
        } else {
          const numY = parseInt((num - (12 - month)) / 12)
          const numM = (num - (12 - month)) % 12
          date.setFullYear(year + numY + 1)
          date.setMonth(numM - 1)
        }
      } else if (type === 'beforeD') { // 日前
        const numD = date.getTime() - 1000 * 60 * 60 * 24 * num
        date = new Date(numD)
      } else if (type === 'afterD') { // 日后
        const numD = date.getTime() + 1000 * 60 * 60 * 24 * num
        date = new Date(numD)
      }
      return date
    },
    // ====================== 日期时间差 ====================
    dateInterval (startTime, endtime, type = 'M') {
      if (!startTime || !endtime) return 0
      const startArr = this.dateFormat(new Date(startTime), 'YYYY-MM-DD').split('-')
      const endArr = this.dateFormat(new Date(endtime), 'YYYY-MM-DD').split('-')
      const endaddArr = this.dateFormat(new Date(this.dayadd(endtime)), 'YYYY-MM-DD').split('-')
      const interval = new Date(endtime).getTime() - new Date(startTime).getTime() // 时间差
      if (type === 'Y') {
        const start = parseInt(startArr[0])
        const end = parseInt(endArr[0])
        return (end - start)
      } else if (type === 'M') {
        const start = parseInt(startArr[0]) * 12 + parseInt(startArr[1])
        // const end = parseInt(endArr[0]) * 12 + parseInt(endArr[1])
        const endadd = parseInt(endaddArr[0]) * 12 + parseInt(endaddArr[1])
        // return (end - start)
        return (endadd - start)
      } else if (type === 'D') {
        return Math.floor(interval / (24 * 60 * 60 * 1000))
      } else {
        return 0
      }
    },
    // ====================== 下载文件 ======================
    download (url, name) {
      // window.open(url) // 该方法只能下载比如xls等不能直接打开的文件，对于png之类的文件会直接打开而非下载
      this.getBlob(url).then(blob => {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, name)
        } else {
          const link = document.createElement('a')
          const body = document.querySelector('body')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          link.style.display = 'none'
          body.appendChild(link)
          link.click()
          body.removeChild(link)
          window.URL.revokeObjectURL(link.href)
        }
      })
    },
    getBlob (url) { // 解决跨域导致a.download无效，无法重命名的问题
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) resolve(xhr.response)
        }
        xhr.send()
      })
    },
    /**
     * @param {Number} timeStamp 传入的时间戳(毫秒级)
     */
    timestamp2Date (timeStamp, startType) {
      const d = new Date(timeStamp)
      const year = d.getFullYear()
      const month = d.getMonth() + 1
      const date = d.getDate()
      const hours = d.getHours()
      const minutes = d.getMinutes()
      const second = d.getSeconds()
      const resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
      return resStr
    },
    // ==================== val 不为空 ====================
    // noNull (val) { return (val || val === 0 || val === false) }
    // 可替换为!!val
    /**
     * @description 格式化性别显示
     * @param genderNum {string} 性别数字
     * @return {string} genderStr 性别名称
     */
    formatterGender (genderNum) {
      switch (genderNum + '') {
        case '0':
          return '女'
        case '1':
          return '男'
        default:
          return '未设置'
      }
    },
    /**
     * @description 格式化性别显示
     * @param genderNum {string} 性别数字
     * @return {string} genderStr 性别名称
     */
    formatterUserRole (role) {
      switch (role + '') {
        case '0':
          return '普通选手'
        case '1':
          return '指导老师'
        default:
          return '未设置'
      }
    },
    /**
     * @description 格式化年级显示
     * @param genderNum {string} 年级
     * @return {string} gradeStr 年级名称
     */
    formatterGrade (grade) {
      switch (grade + '') {
        case '-2':
          return '幼儿园小班'
        case '-1':
          return '幼儿园中班'
        case '0':
          return '幼儿园大班'
        case '1':
          return '一年级'
        case '2':
          return '二年级'
        case '3':
          return '三年级'
        case '4':
          return '四年级'
        case '5':
          return '五年级'
        case '6':
          return '六年级'
        case '7':
          return '初一'
        case '8':
          return '初二'
        case '9':
          return '初三'
        case '10':
          return '高一'
        case '11':
          return '高二'
        case '12':
          return '高三'
        default:
          return ''
      }
    }
  }
})
