// https://developer.mozilla.org/zh-CN/docs/Web/API/IDBDatabase/createObjectStore
const database = { // 数据库信息
  _default: 'fengyongDatabase_erp', // 默认打开的数据库
  _del: [], // 需要清除的 database(没做)
  fengyongDatabase_erp: {
    _del: [], // 需要清除的 store (只需要配置本次修改需要删除的表)
    version: 1, // 数据库版本(若修改以下配置，必须升级版本号)
    stores: { // 表(对象存储空间)
      // [表名]: {
      //   config: { keyPath: '[主键名]', autoIncrement: [是否自增,默认false] },
      //   index: ['[索引名1]', '[索引名2]', ...]
      //   (索引: 索引字段也可以拿到数据记录)
      // }
      // ceshi: {
      //   config: { keyPath: 'id', autoIncrement: true },
      //   index: ['ceshi1', 'ceshi2']
      // },
      // ===== IBC二三轮成绩缓存 =====
      ibcScoreData: {
        config: { keyPath: 'type', autoIncrement: false },
        index: []
      },
      // ===== 所有签到的待签到表 =====
      // type[主键]=签到类型, data=待签到列表
      signInData: {
        config: { keyPath: 'type', autoIncrement: false },
        index: []
      },
      // ===== 所有签到的未签到成功表 =====
      // type[主键]=签到类型, data=未签到成功列表
      signInUncommitData: {
        config: { keyPath: 'type', autoIncrement: false },
        index: []
      }
    }
  }
}
export default {
  db: {}, // 数据库对象集合
  // ========== 打开数据库 ==========
  open (dbName) {
    return new Promise((resolve, reject) => {
      const error = new Error()
      if (!window.indexedDB) {
        error.msg = '您的浏览器暂不支持功能，为了更好的体验，请使用谷歌浏览器(chrome)'
        reject(error)
      }
      // 确定是否传入数据库名，若没有，默认上方 database._default
      dbName = dbName || database._default
      // 判断是否已打开该数据库
      if (this.db[dbName]) return resolve(this.db[dbName])
      // 判断上方是否配置该数据库信息(防止多人开发时乱建)
      if (!database[dbName]) {
        error.msg = '无该数据库信息'
        error.data = dbName
        reject(error)
      }
      // 根据上方数据库信息 打卡/新建 数据库
      const info = database[dbName]
      const dbEl = window.indexedDB.open(dbName, info.version)
      // 打开数据库失败
      dbEl.onerror = (e) => {
        error.msg = '打开数据库失败'
        error.data = e
        reject(error)
      }
      // 打开数据库成功
      dbEl.onsuccess = (e) => {
        console.log('$indexedDB-打开成功', e)
        this.db[dbName] = dbEl.result
        resolve(this.db[dbName])
      }
      // 数据库升级(指定的版本号大于实际版本号)
      // 注1: 基本只触发一次，除非改变上方 数据库的version
      // 注2: 只能在onupgradeneeded回调函数中创建存储空间，而不能在数据库打开后的success回调函数中创建
      dbEl.onupgradeneeded = (e) => {
        console.log('$indexedDB-打开升级', e)
        this.db[dbName] = e.target.result
        // 删除 表(对象存储空间)
        for (const k of info._del) {
          if (this.db[dbName].objectStoreNames.contains(k)) {
            this.db[dbName].deleteObjectStore(k)
          }
        }
        // 创建 表(对象存储空间)
        for (const k in info.stores) {
          const b = info.stores[k]
          let store = null
          // 判断表是否已经创建
          if (!this.db[dbName].objectStoreNames.contains(k)) {
            // 创建 表 (暂不可修改)
            store = this.db[dbName].createObjectStore(k, b.config)
            // 创建 表的索引 (暂不可修改)
            for (const v of b.index) store.createIndex(v, v, { unique: false })
          }
        }
        // resolve(this.db[dbName])
        // 注:
        // onupgradeneeded 优先于 onsuccess 触发,
        // 也就是说触发 onupgradeneeded 必触发 onsuccess,
        // 但是在触发 onupgradeneeded 时不能马上去操作数据库,
        // 所以不要在 onupgradeneeded 中 resolve()
      }
    })
  },
  // ========== 写入数据 ==========
  async set (val, storeName, dbName) {
    // 打开数据库
    const db = await this.open(dbName).catch((error) => {
      console.log('$indexedDB-打开失败', error)
      alert(`$indexedDB-${error.msg}`)
      return Promise.reject(error)
    })
    // 写入数据
    return new Promise((resolve, reject) => {
      const error = new Error()
      const store = db.transaction(storeName, 'readwrite').objectStore(storeName)
      val = Array.isArray(val) ? val : [val]
      for (const item of val) {
        const value = { ...item, lastModifyTime: new Date().getTime() }
        const event = store.put(value)
        // 写入失败
        event.onerror = (e) => {
          console.log('$indexedDB-写入失败', e)
          alert('$indexedDB-写入失败')
          error.msg = '写入失败'
          error.data = e
          reject(error)
        }
        // 写入成功
        event.onsuccess = (e) => {
          console.log('$indexedDB-写入成功', e)
          resolve('success')
        }
      }
    })
  },
  // ========== 读取数据 ==========
  async get (val, storeName, dbName) {
    // 打开数据库
    const db = await this.open(dbName).catch((error) => {
      console.log('$indexedDB-打开失败', error)
      alert(`$indexedDB-${error.msg}`)
      return Promise.reject(error)
    })
    // 读取数据
    return new Promise((resolve, reject) => {
      const error = new Error()
      const store = db.transaction(storeName, 'readwrite').objectStore(storeName)
      const event = !val && val !== 0 ? store.getAll() : store.get(val)
      // 读取失败
      event.onerror = (e) => {
        console.log('$indexedDB-读取失败', e)
        alert('$indexedDB-读取失败')
        error.msg = '读取失败'
        error.data = e
        reject(error)
      }
      // 读取成功
      event.onsuccess = (e) => {
        console.log('$indexedDB-读取成功', e)
        resolve(event.result)
      }
    })
  },
  getIndex () {},
  // ========== 删除数据 ==========
  async del (val, storeName, dbName) {
    // 打开数据库
    const db = await this.open(dbName).catch((error) => {
      console.log('$indexedDB-打开失败', error)
      alert(`$indexedDB-${error.msg}`)
      return Promise.reject(error)
    })
    // 删除数据
    return new Promise((resolve, reject) => {
      const error = new Error()
      const store = db.transaction(storeName, 'readwrite').objectStore(storeName)
      const event = store.delete(val)
      // 删除失败
      event.onerror = (e) => {
        console.log('$indexedDB-删除失败', e)
        alert('$indexedDB-删除失败')
        error.msg = '删除失败'
        error.data = e
        reject(error)
      }
      // 删除成功
      event.onsuccess = (e) => {
        console.log('$indexedDB-删除成功', e)
        resolve(event.result)
      }
    })
  }
}
